import { LogLevel } from '@hcr/utils'

import { LocaleId } from '../models'

import type { EnvironmentVariables } from './types'

export const Environment: EnvironmentVariables = {
  API: {
    Auth: {
      clientId: 'ed9a36a5-56d9-460a-8631-8d000db5a180',
      domain: 'holidayclubcustomerb2c.b2clogin.com',
      redirectUrl: 'https://dev.loma.holidayclubresorts.com',
      url: 'https://holidayclubcustomerb2c.b2clogin.com/holidayclubcustomerb2c.onmicrosoft.com/B2C_1_pwa_signin',
    },
    Consumer: {
      url: 'https://consumer-api.com/mocks',
      useMocks: true,
    },
    Optimizely: {
      url: 'https://optimizely-api.com/mocks',
      useMocks: true,
    },
    Webshop: {
      url: 'https://testing.holidayclubresorts.com',
    },
  },
  Analytics: {
    useAnalytics: false,
  },
  General: {
    localeId: LocaleId.EnUS,
    logLevel: LogLevel.Debug,
    name: 'dev',
  },
  Gomeddo: {
    business: 'hcr-qa2',
    page: '2b35c3ec-3b95-40d6-a3e3-e998ace598ab',
  },
  Sentry: {
    useSentry: false,
  },
  TermsOfCookies: {
    url: {
      [LocaleId.EnUS]: `https://testing.holidayclubresorts.com/en/footermenu/our-web-service/data-protection/privacy-policy/#three-five`,
      [LocaleId.Fi]: `https://testing.holidayclubresorts.com/fi/footermenu/verkkopalvelustamme/tietosuoja/tietosuojaseloste/#evasteet`,
    },
  },
}
